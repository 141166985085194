import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { JoyrideModule } from 'ngx-joyride';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { environment } from '../environments/environment';
import { TitleCasePipe } from '@angular/common';

import { TokenInterceptor } from './shared/middlewares/token.interceptor';
import { LoaderInterceptor } from './shared/middlewares/loader.interceptor';
import { ErrorInterceptor } from './shared/middlewares/error.interceptor';
import { AuthGuard } from './shared/guards/auth.guard';
import { TokenService } from './shared/services/token.service';
import { AuthService } from './shared/services/auth.service';
import { TimerService } from './shared/services/timer.service';
import { LocationService } from './shared/services/location.service';
import { LoginComponent } from './account/login/login.component';
import { LogoutComponent } from './account/logout/logout.component';
import { UnauthorizedComponent } from './account/unauthorized/unauthorized.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './loader/loader.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AdministrationGuard } from './shared/guards/administration.guard';
import { DocumentService } from './rim/inventory/services/document.service';
import { Doc360DocumentService } from './rim/inventory/services/doc-360-document.service';
import { EdmsService } from './rim/inventory/services/edms.service';
// PrimeNG Controls

import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';


@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        UnauthorizedComponent,
        HomeComponent,
        LoaderComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        JoyrideModule.forRoot(),
        OAuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        NgIdleModule.forRoot(),
        OverlayModule,
        RouterModule,
        InputNumberModule,
        MenubarModule,
        ProgressSpinnerModule,
        DialogModule,
        ButtonModule], providers: [
        AuthService,
        TokenService,
        AuthGuard,
        AdministrationGuard,
        TimerService,
        TitleCasePipe,
        LocationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        { provide: DocumentService, useFactory: documentServiceFactory, deps: [HttpClient] },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
export function documentServiceFactory(httpClient: HttpClient) {
  if (environment.useDoc360) {
    return new Doc360DocumentService(httpClient)
  }
  else {
    return new EdmsService(httpClient);
  }
}